export type MasseurList = {
	file: string
	name: string
	modal: boolean
}

export const useMasseurList = (): MasseurList[] => {
	return [
		{
			file: 'micaele_2025-01-14',
			name: 'Massoterapeuta Micaele',
			modal: true
		},
		{
			file: 'agatha_2025-01-14',
			name: 'Massoterapeuta Ágatha',
			modal: true
		},
		{
			file: 'duda_2025-01-14',
			name: 'Massoterapeuta Duda',
			modal: true
		}
	]
}
