import revive_payload_client_yQ77ZNWu78 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__n72twpns6trc2gtnciqbkh2reu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_UE6beQMVJa from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__n72twpns6trc2gtnciqbkh2reu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DxQtXMlkx3 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__n72twpns6trc2gtnciqbkh2reu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_56spYtxYkj from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__n72twpns6trc2gtnciqbkh2reu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_BxJmUoSYzI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__n72twpns6trc2gtnciqbkh2reu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_nAxCWEQDFS from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__n72twpns6trc2gtnciqbkh2reu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_BBnas9ggmK from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__n72twpns6trc2gtnciqbkh2reu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_13kHgjMB9t from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__n72twpns6trc2gtnciqbkh2reu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_yQ77ZNWu78,
  unhead_UE6beQMVJa,
  router_DxQtXMlkx3,
  payload_client_56spYtxYkj,
  navigation_repaint_client_BxJmUoSYzI,
  check_outdated_build_client_nAxCWEQDFS,
  chunk_reload_client_BBnas9ggmK,
  components_plugin_KR1HBZs4kY,
  prefetch_client_13kHgjMB9t
]