export type MassageList = {
	title: string
	description: string | null
}

export const useMassageList = (): MassageList[] => {
	return [
		{
			title: 'Relaxante',
			description: 'Trata-se de manobras especializadas empregadas no músculo, conferindo ao indivíduo relaxamento e alívio muscular.'
		},
		{
			title: 'Tântrica',
			description: 'A massagem tântrica é uma massagem muito relaxante, o poder do toque te leva à outro estágio ativando sua energia sexual e com técnicas e manobras da massagem peniana pode fazer você chegar ao orgasmo.'
		},
		{
			title: 'Inversa',
			description: null
		},
		{
			title: 'Nuru',
			description: 'A massagem nuru lhe proporcionar uma nova experiência extremamente sensual e diferenciada, ela envolve carinho seguido de toques, deslizamento, movimento dos corpos e a lingam massagem que vão lhe levar ao êxtase do prazer.<br />Com o diferencial que a massagista estará aplicando totalmente nua.'
		},
		{
			title: 'Quatro Mãos',
			description: 'A massagem a 4 mãos é realizada por dois massoterapeutas simultaneamente, aplicada nos lados direito e esquerdo do corpo, proporcionando ao paciente duplo relaxamento.'
		},
		{
			title: 'Com Pedras Quentes',
			description: 'As pedras são utilizadas com a finalidade de proporcionar uma ação terapêutica para aliviar a dor, relaxar a musculatura, proporcionar bem-estar e promover desintoxicação.'
		},
		{
			title: 'Ventosaterapia',
			description: 'A ventosaterapia possibilita o relaxamento do corpo e da mente, fortalecimento dos vasos sanguíneos, reduz dores e inflamações, melhora o fluxo sanguíneo e a circulação, eliminação de contraturas musculares e pontos de tensão.'
		},
		{
			title: 'Liberação Miofascial',
			description: null
		},
		{
			title: 'Esfoliação Corporal',
			description: null
		},
		{
			title: 'Drenagem',
			description: null
		}
	]
}
